import {useInView} from "react-intersection-observer";

import estructionsMockup from "../../img/mockups/nyeveier-mockup-blur.png";

import threejsLogo from "../../img/logos/threejs.png";
import figmaLogo from "../../img/logos/figma.png";
import tailwindLogo from "../../img/logos/tailwind.png";
import reactLogo from "../../img/logos/react.png";
import nyeVeierLogo from "../../img/mockups/logo-color.png";
import mapboxLogo from "../../img/logos/Mapbox.png";
import typesScriptLogo from "../../img/logos/Typescript.png";
import swiploadLogo from "../../img/logos/swipload.png";
import svelteLogo from "../../img/logos/svelte.png";
import dotnetLogo from "../../img/logos/dotnet.png";
import azureLogo from "../../img/logos/azure.png";
import meSwipload from "../../img/me-swipload.png";
import playwrightLogo from "../../img/logos/playwright.png";

export default function Swipload() {
	const programs = [
		{link: "", name: "SvelteKit", image: svelteLogo},
		{link: "", name: "TypeScript", image: typesScriptLogo},
		{link: "", name: "Tailwind", image: tailwindLogo},
		{link: "", name: ".NET", image: dotnetLogo},
		{link: "", name: "Azure", image: azureLogo},
		{link: "", name: "Playwright", image: playwrightLogo},
	];

	const goToPage = () => {
		return () => {
			window.open("https://swipload.com"); // Redirect to the specified link
		};
	};

	const {ref: program, inView: programVisible} = useInView();
	const {cont, inView: contVisible} = useInView();

	return (
		<div ref={cont} className={` h-auto pt-10 pb-12 flex flex-col items-center `}>
			<div
				onClick={goToPage()}
				className=" max-w-[920px] w-[75vw] min-h-[70vh] p-10 rounded-3xl border-2  border-blue-500 transition-all duration-300 cursor-pointer hover:bg-neutral-800">
				<div className="flex flex-col lg:flex-row gap-4 items-end mb-4">
					<img className=" w-auto m-auto max-h-[50px]" src={swiploadLogo} />

					<img className=" m-auto max-h-[30vh] object-contain sm:-mt-4 mt-0 rounded-3xl" src={meSwipload} />
				</div>
				<p className="text-white lg:text-xl lg:font-light text-center md:text-left font-light text-sm leading-4 sm:mt-0 mt-4 ">
					I'm currently working as a front-end developer at Swipload, where I am responsible for developing and maintaining the frontend of the
					platform. Our goal is to create user-friendly and efficient solutions for our users. I am also involved in the development of new features
					and improvements to the platform, ensuring that it meets the needs of our users and stays up-to-date with the latest technologies.
				</p>
				<h3 className="text-neutral-500 text-center text-xl mt-5">Tools</h3>

				<div ref={program} className=" mt-2 m-auto flex gap-4 justify-center flex-wrap">
					{programs.map((program, index) => (
						<div
							style={{animationDelay: `${0.05 * (index + 1)}s`}}
							className={`${
								programVisible ? "slide-in" : ""
							} opacity-0 relative h-14 w-14 md:h-24 md:w-24 p-2 md:rounded-3xl rounded-lg  flex flex-col gap-2 justify-center transition-all duration-200 bg-neutral-700 bg-opacity-25`}>
							<img className=" object-scale-down h-6 sm:h-12 hover:grayscale-0" src={program.image} alt={program.name} />
							<h4 className="text-center text-neutral-200 font-medium text-xss md:text-sm -mt-2 ">{program.name}</h4>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
